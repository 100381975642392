import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Avatar from "../components/avatar";
import Layout from "../components/site-content-layout";
export const _frontmatter = {
  "title": "About Me",
  "description": "I’m Victor Nakoriakov. Developer and entrepreneur."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About Me`}</h1>
    <Avatar mdxType="Avatar" />
    <p>{`Hello, I’m Victor Nakoriakov. Developer and entrepreneur living in Russia. Currently, I’m running a successful business, the Amperka company (`}<a parentName="p" {...{
        "href": "https://amperka.ru/?utm_source=fsd",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`amperka.ru`}</a>{` — the essense, `}<a parentName="p" {...{
        "href": "https://amperka.com/?utm_source=fsd",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`amperka.com`}</a>{` — a little lovely branch). We are a very well-known vendor and seller of DIY-electronics in Russia. I’m the founder and CTO here.`}</p>
    <p>{`Nevertheless, programming and computer science is still my passion. Sometimes I invincibly want to write and share my mind. I used to write on collective blog platforms but they slip away. That’s the reason the blog exists.`}</p>
    <p>{`I also maintain and create various software things for the company and as side-projects. If only I had enough energy, I could share a very long story. For the time of my career I have had close experience with:`}</p>
    <ul>
      <li parentName="ul">{`C/C++, Python, JavaScript, ReasonML/ReScript`}</li>
      <li parentName="ul">{`Software architecture`}</li>
      <li parentName="ul">{`Building web-based software`}</li>
      <li parentName="ul">{`RDBMS and SQL`}</li>
      <li parentName="ul">{`Distributed computing and various message brokers`}</li>
      <li parentName="ul">{`3D Graphics and OpenGL`}</li>
      <li parentName="ul">{`Linux`}</li>
      <li parentName="ul">{`Arduino, Raspberry Pi, electronics engineering`}</li>
      <li parentName="ul">{`DevOps, continuous integration and delivery`}</li>
      <li parentName="ul">{`Agile project management, scrum`}</li>
    </ul>
    <p>{`I’d list a hundred of frameworks, libraries, and tools as well but it has a little sense because they change every season.`}</p>
    <h2>{`Why “Full Steak,” isn’t it a typo?`}</h2>
    <p>{`Kind of. The site name is dedicated to ignorant head hunters in Russia. From time to time they reach me and suggest their services to find “the best full-steak developers” for us. I also see a lot of vacancies looking for “full-steak developers”. They write them, they pay for them. When an HR suggests me full-steak developers I’m asking if they can find “medium-rare developers”… and this uncomfortable pause is the most funny thing in the talk 😝`}</p>
    <p>{`Besides that, I like cooking. Hence the name.`}</p>
    <h2>{`Can we make a business?`}</h2>
    <p>{`If you’d like to invite me to a conference as a speaker, sure, let’s discuss. Or if you have another kind of offer, feel free to write me too. `}<a parentName="p" {...{
        "href": "mailto:victor@fullsteak.dev",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`victor@fullsteak.dev`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      