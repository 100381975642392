import * as React from "react";
import { MDXProvider } from "@mdx-js/react";
import Layout from "./layout";
import Seo from "./seo";

function SiteContentLayout({ children }) {
  return (
    <Layout pageTitle="About Me">
      <Seo
        title="About Me"
        description="I’m Victor Nakoriakov. Developer and enterpreneur."
      />
      <article className="post">
        <MDXProvider>{children}</MDXProvider>
      </article>
    </Layout>
  );
}

export default SiteContentLayout;
