import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

function Avatar() {
  return (
    <div
      style={{
        float: "right",
        margin: "2rem",
        textAlign: "center",
        fontSize: "10px",
      }}
    >
      <StaticImage
        src="../images/roller-coaster-avatar.jpg"
        width={300}
        style={{
          borderRadius: "50%",
          border: "10px solid #ececec",
        }}
        alt="My avatar"
      />
      <div>❤️ roller coasters</div>
    </div>
  );
}

export default Avatar;
